



































































































import {
  Vue,
  Component,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import AddressKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/address/address-kuspit-view-model';

@Component({
  name: 'ContractSavingsPersonalInformationAddressAllianz',
  components: { CustomAutocomplete },
})
export default class ContractSavingsPersonalInformationAddressKuspit extends Vue {
  @PropSync('addressForm', { type: Boolean })
  synced_address_form!: boolean;

  address_kuspit_view_model = Vue.observable(
    new AddressKuspitViewModel(),
  );

  @Watch('address_kuspit_view_model.confirm_address')
  handleConfirmInformationChange(new_confirmation: boolean) {
    this.address_kuspit_view_model.handleAgreementChange(
      'confirm_address_information_kuspit',
      new_confirmation,
    );
    this.$emit('confirmAddress', new_confirmation);
  }

  async created() {
    this.address_kuspit_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.address_kuspit_view_model.initialize();
  }

  async saveStep() {
    const saved = await this.address_kuspit_view_model.saveStep();
    return saved;
  }
}

