import TYPES from '@/types';
import { v4 as uuid } from 'uuid';

// Application
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import {
  SearchAddressQuery,
} from '@/modules/my-investment/catalogs/kuspit/address/application/queries';
import SearchCustomerAddressQuery
  from '@/modules/my-investment/address/application/queries/search-address-query';

// Domain
import {
  AgreementEntity,
} from '@/modules/my-investment/agreements/domain/entities/agreement-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { postalCodeFormat, requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  CustomerAddressEntity,
} from '@/modules/my-investment/address/domain/entities/customer-address-entity';
import {
  CustomerAgreementEntity,
} from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import {
  AddressEntity,
} from '@/modules/my-investment/catalogs/kuspit/address/domain/entities/address-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AddressKuspitViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  private readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ADDRESS_QUERY)
  private readonly searchAddressQuery!: SearchAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ADDRESS_QUERY)
  private readonly searchCustomerAddressQuery!: SearchCustomerAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  step_name = 'on_boarding_kuspit_address';

  investment_provider_id = '';

  investment_provider_name = 'kuspit';

  exists_step = false;

  i18n_namespace = 'components.contract-savings.personal-information.address';

  small_screen: null | boolean = null;

  confirm_address = false;

  address = {
    street: '',
    external_number: '',
    interior_number: '',
    zip_code: '',
    complementary_address: {
      id: '',
      descripcion: '',
    },
  };

  rules = {
    street: [requiredRule],
    external_number: [requiredRule],
    postal_code: [requiredRule, postalCodeFormat],
    complementary_address: [
      requiredRule,
      (value: any) => requiredRuleByKey(value, 'id'),
    ],
  };

  private on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  user_agreements: Array<CustomerAgreementEntity> = [];

  agreements_list: Array<AgreementEntity> = [];

  complementary_addresses: Array<AddressEntity> = [];

  is_loading = false;

  get agreements() {
    return this.searchAgreementsAsyncQuery.execute();
  }

  get customerAddresses() {
    return this.searchCustomerAddressQuery.execute();
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  loadComplementaryAddress = async () => {
    this.complementary_addresses = [];
    if (this.address.zip_code) {
      if (this.address.zip_code.length === 5) {
        this.complementary_addresses = await this.searchAddressQuery.execute(this.address.zip_code);
      }
    }
  }

  restoreComplementaryAddress = (event: Event) => {
    if (this.address.zip_code.length === 5) {
      this.address.complementary_address = {
        id: '',
        descripcion: '',
      };
    }
  }

  setInputsValues = async (customer_addresses: Array<CustomerAddressEntity>) => {
    const address_default = customer_addresses.find(
      (address) => address.customer_address_default,
    );
    if (address_default) {
      this.address.street = address_default.address.street;
      this.address.external_number = address_default.address.external_number;
      this.address.interior_number = address_default.address.interior_number || '';
      this.address.zip_code = address_default.address.zip_code;
      await this.loadComplementaryAddress();
    }
  };

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      await this.setInputsDataFromStep();
    } else {
      const customer_addresses = await this.customerAddresses;
      await this.setInputsValues(customer_addresses);
    }
  };

  setInputsDataFromStep = async () => {
    this.address.street = this.on_boarding_step.payload.street;
    this.address.external_number = this.on_boarding_step.payload.external_number;
    this.address.interior_number = this.on_boarding_step.payload.interior_number;
    this.address.zip_code = this.on_boarding_step.payload.zip_code;
    await this.loadComplementaryAddress();
    this.address.complementary_address = {
      id: this.on_boarding_step.payload.address.id,
      descripcion: this.on_boarding_step.payload.address.description,
    };
    this.confirm_address = this.on_boarding_step.payload.agreements.length > 0;
  };

  setUserAgreements = async () => {
    this.agreements_list = await this.searchAgreementsAsyncQuery.execute();
    if (!this.on_boarding_step.payload.agreements
      || !this.on_boarding_step.payload.agreements.length) {
      this.createAgreement('confirm_address_information_kuspit', this.agreements_list);
    } else {
      this.user_agreements = this.on_boarding_step.payload.agreements;
    }
  };

  handleAgreementChange(agreement_name: string, confirmed: boolean) {
    const agreement = this.agreements_list.find(
      (item) => item.name === agreement_name,
    );

    if (agreement) {
      const user_agreement_index = this.user_agreements.findIndex(
        (item) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement_index >= 0) {
        // eslint-disable-next-line max-len
        this.user_agreements[user_agreement_index].accepted_on = confirmed ? this.datetime_value.create() : null;
        this.user_agreements[user_agreement_index].was_accepted = confirmed;
      }
    }
  }

  createAgreement(agreement_name: string, agreements_list: Array<AgreementEntity>) {
    const agreement = agreements_list.find(
      (item) => item.name === agreement_name,
    );
    if (agreement) {
      this.user_agreements.push({
        id: uuid(),
        agreement_type_id: agreement.id,
        accepted_on: null,
        description: agreement.description,
        was_accepted: false,
      });
    }
  }

  async saveStep() {
    this.is_loading = true;
    const on_boarding_steps = await this.steps;
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.street = this.address.street;
      this.on_boarding_step.payload.external_number = this.address.external_number;
      this.on_boarding_step.payload.interior_number = this.address.interior_number;
      this.on_boarding_step.payload.zip_code = this.address.zip_code;
      this.on_boarding_step.payload.address = {
        id: this.address.complementary_address.id,
        description: this.address.complementary_address.descripcion,
      };
      this.on_boarding_step.payload.agreements = this.user_agreements;
      if (search_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    await this.setUserAgreements();
  }
}
