




















import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';

@Component({ name: 'CustomAutocomplete' })
export default class CustomAutocomplete extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Array) items?: Array<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Array) rules?: Array<any>;

  @Prop(String) value?: string;

  @Prop(String) text?: string;

  @Prop({ default: false }) is_disabled?: boolean;

  @Prop({ default: 'primary' }) color?: string;

  @Prop({ default: 'Selecciona...' }) placeholder?: string;

  @Prop(String) dense?: string;

  @PropSync('model')
  synced_model?: object | undefined

  change = (value: never) => {
    this.$emit('change', value);
  }

  // eslint-disable-next-line class-methods-use-this
  normalizeString(input_string: string) {
    let normalized = input_string.toLowerCase();
    try {
      normalized = normalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } catch {
      // eslint-disable-next-line no-empty
    }
    return normalized;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customSearchFilter(item: any, queryText: string, itemText: string): boolean {
    const itemName = this.normalizeString(itemText || '');
    const searchText = this.normalizeString(queryText || '');
    return itemName.indexOf(searchText) > -1;
  }
}
